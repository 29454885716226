exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-hanna-tsx": () => import("./../../../src/pages/hanna.tsx" /* webpackChunkName: "component---src-pages-hanna-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-leistungen-alternativ-tsx": () => import("./../../../src/pages/leistungen/alternativ.tsx" /* webpackChunkName: "component---src-pages-leistungen-alternativ-tsx" */),
  "component---src-pages-leistungen-medizinisch-tsx": () => import("./../../../src/pages/leistungen/medizinisch.tsx" /* webpackChunkName: "component---src-pages-leistungen-medizinisch-tsx" */),
  "component---src-pages-leistungen-wellness-tsx": () => import("./../../../src/pages/leistungen/wellness.tsx" /* webpackChunkName: "component---src-pages-leistungen-wellness-tsx" */),
  "component---src-pages-yoga-tsx": () => import("./../../../src/pages/yoga.tsx" /* webpackChunkName: "component---src-pages-yoga-tsx" */)
}

